<template>
    <div data-attr="tags">
        <div class="d-flex justify-space-between align-center mb-3">
            <div>
                <h4 class="text-uppercase">Tags</h4>
            </div>
            <div>
                <v-btn fab plain x-small @click="edit('tags')">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>
  
<script>
  import moment from 'moment';
  
  export default {
      name: 'Tags',
      props: {
          contactData: {
              required: true,
              type: Object
          }
      },
      methods: {
          edit(param) {
              this.$emit('edit', param)
          },
          toReadableDate(date) {
              if(!date) return;
              return moment(date).format('MMMM DD, YYYY');
          },
      }
  }
  </script>
  
  <style>
  
  </style>