<template>
  <div>
    <div class="d-flex justify-space-between align-center">
        <div>
            <h1>
                {{ contactData['prefix'] }}
                {{ contactData['preferred_name'] ?  contactData['preferred_name'] : contactData['first_name']}} 
                {{ contactData['middle_name'] }} {{ contactData['last_name'] }} 
            </h1>
        </div>
        <div>
            <v-btn fab plain x-small @click="edit('main')">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </div>
    </div>

    <div class="d-flex mt-3">
        <v-btn fab small depressed color="grey lighten-4" class="mr-2">
            <v-icon small>mdi-email-outline</v-icon>
        </v-btn>
        <v-btn fab small depressed color="grey lighten-4" class="mr-2">
            <v-icon small>mdi-message-outline</v-icon>
        </v-btn>
        <v-btn fab small depressed color="grey lighten-4" class="mr-2">
            <v-icon small>mdi-text-long</v-icon>
        </v-btn>
        <v-btn fab small depressed color="grey lighten-4" class="mr-2">
            <v-icon small>mdi-check</v-icon>
        </v-btn>
    </div>

    <div class="mt-5">
        <div data-attr="main">
            <v-row no-gutters class="my-2">
                <v-col sm="6">Total Premium</v-col>
                <v-col sm="6" class="font-weight-black">$ {{ contactData['total_premium'] }}</v-col>
            </v-row>
            <v-row no-gutters class="my-2">
                <v-col sm="6">Client Since</v-col>
                <v-col sm="6" class="font-weight-black">{{ toReadableDate(contactData['client_since']) }}</v-col>
            </v-row>
            <v-row no-gutters class="my-2">
                <v-col sm="6">Personal</v-col>
                <v-col sm="6">
                    <v-chip class="mb-1" small v-for="(item, index) in contactData['relationships']" :key="'p-'+index">
                        <span>{{ item['name'] }}</span>
                    </v-chip>
                </v-col>
            </v-row>
            <v-row no-gutters class="my-2">
                <v-col sm="6">Commercial</v-col>
                <v-col sm="6">
                    <v-chip class="mb-1" small v-for="(item, index) in contactData['businesses']" :key="'c-'+index">
                        <span>{{ item['name'] }}</span>
                    </v-chip>
                </v-col>
            </v-row>

            <v-divider class="my-5"></v-divider>
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'ContactInfo',
    props: {
        contactData: {
            required: true,
            type: Object
        }
    },
    methods: {
        edit(param) {
            this.$emit('edit', param)
        },
        toReadableDate(date) {
            if(!date) return;
            return moment(date).format('MMMM DD, YYYY');
        },
    }
}
</script>

<style>

</style>