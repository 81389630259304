<template>
    <v-dialog v-model="commercialDialog" width="400" scrollable persistent>
        <v-card>
            <v-card-title>
                <h5>Add Commercial Links</h5>
            </v-card-title>
            <v-card-text class="pt-5">

                <v-autocomplete 
                    chips
                    return-object
                    item-text="text"
                    item-value="id"
                    no-filter
                    :items="businessItems"
                    :search-input.sync="businessSearch"
                    v-model="business_search"
                    label="Search Contact"
                    append-outer-icon="mdi-magnify"
                    placeholder="Start typing to Search">
                </v-autocomplete>

                <v-select
                    persistent-placeholder
                    dense
                    v-model="business_type"
                    :items="business_roles"
                    label="Business Role"
                    placeholder="Select Business Role"
                    outlined
                ></v-select>

            </v-card-text>
            <v-card-actions class="d-flex justify-end mt-5">
                <v-btn @click="commercialDialog = false">Close</v-btn>
                <v-btn @click="addBusiness" color="teal" class="white--text">Add Link</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ContactCommercial',
    data() {
        return {
            commercialDialog: false,
        }
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        addBusiness() {
            this.$emit('add')
        }
    },
    watch: {
        dialog(newVal) {
            if(newVal) {
                this.commercialDialog = newVal
            }
        }
    }
}
</script>

<style>

</style>