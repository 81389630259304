<template>
    <v-dialog v-model="personalDialog" width="400" scrollable persistent>
                <v-card>
                    <v-card-title>
                        <h5>Add Personal Links</h5>
                    </v-card-title>
                    <v-card-text class="pt-5">

                        <ValidationObserver ref="personalLink">
                            <ValidationProvider name="Contact" rules="required" v-slot="{ errors }">
                                <v-autocomplete 
                                    :error-messages="errors"
                                    chips
                                    return-object
                                    item-text="text"
                                    item-value="id"
                                    no-filter
                                    :items="items"
                                    :search-input.sync="search"
                                    v-model="contact_search"
                                    label="Search Contact"
                                    append-outer-icon="mdi-magnify"
                                    placeholder="Start typing to Search">
                                </v-autocomplete>
                            </ValidationProvider>

                            <ValidationProvider name="Label" rules="required" v-slot="{ errors }">
                                <v-select
                                    persistent-placeholder
                                    :error-messages="errors"
                                    dense
                                    v-model="contact_label"
                                    :items="relationships"
                                    item-value="value"
                                    label="Label"
                                    placeholder="Select Label"
                                    outlined
                                ></v-select>
                            </ValidationProvider>

                            <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                                <v-select
                                    persistent-placeholder
                                    :error-messages="errors"
                                    dense
                                    v-model="contact_type"
                                    item-value="value"
                                    :items="relationship_statuses"
                                    label="Status"
                                    placeholder="Select status"
                                    outlined
                                ></v-select>
                            </ValidationProvider>
                        </ValidationObserver>

                        
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end mt-5">
                        <v-btn @click="personalDialog = false">Close</v-btn>
                        <v-btn @click="addPersonal" color="teal" class="white--text">Add Link</v-btn>
                    </v-card-actions>
                </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ContactPersonal',
    data() {
        return {
            personalDialog: false,
        }
    },
    methods: {
        addPersonal() {
            this.$emit('add')
        }
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    watch: {
        dialog(newVal) {
            if(newVal) {
                this.personalDialog = newVal
            }
        }
    }
}
</script>

<style>

</style>