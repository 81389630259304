<template>
    <div>
        <v-card flat>
            <v-card-text>
                
                <ContactInfo :contact-data="contactData" @edit="edit"/>
                <ContactDetails :contact-data="contactData" @edit="edit"/>
                <PersonalDetails :contact-data="contactData" @edit="edit"/>
                <Tags :contact-data="contactData" @edit="edit"/>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment';
import ContactInfo from '@/components/Rail/ContactInfo'
import ContactDetails from '@/components/Rail/ContactDetails'
import PersonalDetails from '@/components/Rail/PersonalDetails'
import Tags from '@/components/Rail/Tags'

export default {
    name: 'ContactRail',
    components: {
        ContactInfo,
        ContactDetails,
        PersonalDetails,
        Tags
    },
    props: {
        contactData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods : {
        toReadableDate(date) {
            if(!date) return;
            return moment(date).format('MMMM DD, YYYY');
        },
        edit(key) {
            this.$emit('open-modal', true, key);
        }
    }
}
</script>