<template>
  <div class="px-5 fill-height overflow-auto" style="height: 50vh">
    <h3>Add New Policy</h3>

    <div class="mt-10">
        <v-row no-gutters class="mb-2">
            <v-col cols="5">
                Business:
            </v-col>
            <v-col cols="7">
                <v-radio-group dense v-model="form.is_renewal" :column="false" class="mt-0 pa-0 ba-label">
                    <v-radio label="Existing" :value="false" class="mr-5"></v-radio>
                    <v-radio label="New" :value="true"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
    </div>
    
    <div>
        <div v-if="form.is_renewal" no-gutters>
            <v-row no-gutters>
                <v-col cols="5">
                    Is this a new opportunity?
                </v-col>
                <v-col cols="7">
                    <v-radio-group dense v-model="form.is_new" :column="false" class="mt-0 pa-0 ba-label">
                        <v-radio label="Yes" :value="true" class="mr-5"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </div>

        <div v-else>
            <ValidationObserver ref="policyForm">
                <v-row no-gutters class="mt-1">
                    <v-col cols="5" class="pt-3">
                        Policy Type:
                    </v-col>
                    <v-col cols="7">
                        <ValidationProvider name="Policy Type" rules="required" v-slot="{ errors }" vid="policy_type_id">
                            <v-select
                                :error-messages="errors"
                                outlined
                                dense
                                :items="policyTypes"
                                item-value="id"
                                item-text="text"
                                v-model="form.policy_type_id">
                            </v-select>
                        </ValidationProvider>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-1">
                    <v-col cols="5" class="pt-3">
                        Policy Number:
                    </v-col>
                    <v-col cols="7">
                        <ValidationProvider name="Policy Number" rules="required" v-slot="{ errors }" vid="policy_number">
                            <v-text-field 
                                :error-messages="errors"
                                outlined
                                dense 
                                v-model="form.policy_number">
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-1">
                    <v-col cols="5" class="pt-3">
                        Effective Date
                    </v-col>
                    <v-col cols="7">
                        <v-menu
                            ref="start_date"
                            v-model="start_date"
                            :close-on-content-click="false"
                            :return-value.sync="form.start_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <ValidationProvider name="Policy Number" rules="required" v-slot="{ errors }" vid="start_date">
                                    <v-text-field
                                        :error-messages="errors"
                                        v-model="form.start_date"
                                        label="Select Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </ValidationProvider>
                            </template>
                            <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                                :max="maxDate"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="start_date = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.start_date.save(date)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-1">
                    <v-col cols="5" class="pt-3">
                        Term Length
                    </v-col>
                    <v-col cols="7">
                        <ValidationProvider name="Term Length" rules="required" v-slot="{ errors }" vid="terms">
                            <v-select
                                :error-messages="errors"
                                outlined
                                dense
                                :items="terms"
                                item-value="value"
                                item-text="text"
                                v-model="form.terms">
                            </v-select>
                        </ValidationProvider>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-1">
                    <v-col cols="5" class="pt-3">
                        Carrier:
                    </v-col>
                    <v-col cols="7">
                        <ValidationProvider name="Carrier" rules="required" v-slot="{ errors }" vid="carrier_id">
                            <v-select
                                :error-messages="errors"
                                outlined
                                dense
                                :items="carriers"
                                item-value="id"
                                item-text="text"
                                v-model="form.carrier_id">
                            </v-select>
                        </ValidationProvider>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-1">
                    <v-col cols="5" class="pt-3">
                        Premium
                    </v-col>
                    <v-col cols="7">
                        <ValidationProvider name="Premium" rules="required" v-slot="{ errors }" vid="premium">
                            <v-text-field 
                                :error-messages="errors"
                                outlined
                                dense 
                                v-model="form.premium">
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-1">
                    <v-col cols="5" class="pt-3">
                        Commision Split
                    </v-col>
                    <v-col cols="7">
                        <ValidationProvider name="Commision Split" rules="required" v-slot="{ errors }" >
                            <v-text-field 
                                :error-messages="errors"
                                outlined
                                dense 
                                v-model="form.commision">
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>

                
            </ValidationObserver>
        </div>
    </div>

    <div class="sr-only mt-10"></div>

  </div>
</template>

<script>
import carriers from '@/api/carriers';
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'PolicyCreateForm',
    props: {
        saving: {
            type: Boolean
        },
        policyTypes: {
            type: Array,
            required: true
        },
        carriers: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            form: {
                is_renewal: false,
                is_new: false,
                carrier_id: null,
                contact_id: null,
                policy_number: null,
                policy_type_id: null,
                premium: null,
                start_date: null,
                terms: null
            },
            start_date: false,
            maxDate: new Date().toISOString().substr(0, 10),
            terms: [
                { text: '6 months', value: 6 },
                { text: '12 months', value: 12 },
                { text: 'Other', value: -1 },
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISO
        }
    },
    computed: {
        ...mapGetters(['getPolicyCreateForm'])
    },
    methods: {
        ...mapActions(['setToast']),
        async createNew() {

            try {

                this.form.contact_id = this.$route.params.id
                
                let res = await this.$api.policies.create(this.form);

                this.setToast({
                    show: true,
                    message: 'Policy successfully created!',
                    type: 'success'
                })

                let policy = {
                    ...res.data.attributes,
                    type: res.data.related.type?.data,
                    owner: res.data.related.owner?.data,
                    insured: res.data.related.insured?.data,
                    carrier: res.data.related.carrier?.data,
                    user: res.data.related.user?.data
                }

                this.$emit('saved', policy);

            } catch (error) {

                this.setToast({
                    show: true,
                    message: error.response.data.message,
                    type: 'error'
                })

                this.$refs.policyForm.setErrors(error.response.data.errors)

                this.$emit('saved', null);
            }
        }
    },
    watch: {
        saving(newVal) {
            if(newVal) {
                this.createNew();
            }
        }
    }
}
</script>

<style lang="scss">
.ba-label label {
    font-size: 1em;
}
</style>
