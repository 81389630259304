<template>
    <div class="overflow-auto fill-height policy-view px-2 py-0">

        <!-- Policy Info -->
        <div class="mb-10">
            <h3 class="mb-4">{{ policy.type.attributes.name }}</h3>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Assigned Owner:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ policy.owner.attributes.name }}</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Main Contact:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ policy.user !== null ? policy.user.attributes.name : '' }}</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    New / Existing Policy:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ policy.is_renewal ? 'New' : 'Existing' }}</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Policy Status:
                </v-col>
                <v-col cols="12" sm="7">
                    <span class="text-capitalize"><strong>{{ policy.status }}</strong></span>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Policy Sub Status:
                </v-col>
                <v-col cols="12" sm="7">
                    <span class="text-capitalize"><strong>{{ policy.status }}</strong></span>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Policy Type:
                </v-col>
                <v-col cols="12" sm="7">
                    <span class="text-capitalize"><strong>{{ policy.type.attributes.category }}</strong></span>
                </v-col>
            </v-row>
        </div>

        <!-- Policy Details -->
        <div>
            <h3 class="mb-4">Policy Details</h3>
            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Additional Insured:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>No additional insured found.</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Commision:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>--</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Carrier:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ policy.carrier.attributes.display }}</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Effective Date:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ policy.start_date }}</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Insured:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ policy.user !== null ? policy.user.attributes.name : '' }}</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Policy Number:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ policy.policy_number }}</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Premium Amount($):
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ $moment(policy.start_date).format('LL') }}</strong>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="5">
                    Term Length:
                </v-col>
                <v-col cols="12" sm="7">
                    <strong>{{ policy.terms }}</strong>
                </v-col>
            </v-row>
        </div>

        <div class="mb-5">
            <v-row class="mt-0" no-gutters>
                <v-col cols="12" class="py-0 mb-8 mt-5"><h3>Notes History</h3></v-col>
            </v-row>

            <v-row class="mt-0 px-2" no-gutters>
                <v-col cols="12">
                    <v-alert
                        v-for="(item, index) in 2" :key="index"
                        border="left"
                        colored-border
                        color="blue lighten-3"
                        elevation="2"
                        class="body-2 mb-5 mt-2"    
                    >
                        <div class="d-flex justify-space-between align-center mb-3">
                            <div>
                            <div class="caption">
                                <v-icon small class="mr-2">mdi-format-align-left</v-icon> <strong>Note </strong> by Jordan Hatch
                            </div>
                            </div>
                            <div class="caption">
                            <div>May 2, 2022 at 8:39 PM MST</div>
                            </div>
                        </div>

                        <div class="pl-5 body-2">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque cum maxime libero explicabo assumenda! Natus obcaecati illo modi ducimus mollitia.
                        </div>
                    </v-alert>
                </v-col>
            </v-row>
        </div>

        <div class="mb-5">
            <v-row class="mt-0" no-gutters>
                <v-col cols="12" class="py-0 mb-8"><h3>Service Details</h3></v-col>
            </v-row>
        </div>

        <div class="mb-5">
            <v-row class="mt-0" no-gutters>
                <v-col cols="12" class="py-0 mb-8"><h3>Attachments</h3></v-col>
            </v-row>
        </div>

        <div class="sr-only mt-10"></div>
    </div>
</template>

<script>
export default {
    name: 'PolicyView',
    props: {
        policy: {
            default: () => {}
        }
    }
}
</script>

<style lang="scss">
.policy-view {
    height: 50vh;
}

</style>