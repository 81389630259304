<template>
  <div>
    <PolicyModal @close="closePolicyModal" :show="policyModal"/>

    <v-row no-gutters class="h-full">
      <v-col
          cols="4" class="white h-full">
          
          <ContactRail 
            :contact-data="contact" 
            @open-modal="openModal" />

      </v-col>
      <v-col 
          cols="8" 
          class="h-full pl-3">
          
          <div class="d-flex justify-space-between mt-10 mb-5 align-center">
            <div>
              <h5>POLICIES ({{ contact.total_policy_count }}) TOTAL ACTIVE ${{ contact.total_premium }}</h5>
            </div>
            <div>
              <v-btn outlined color="success" @click="newPolicy()">Add</v-btn>
            </div>
          </div>

          <v-slide-group class="mb-10">

            <PolicyCard v-for="(policy, index) in contact.policies" 
              :key="index" :policy="policy" 
              @select="openPolicyDetailsModal"
              :selected="selectedPolicy && selectedPolicy.id == policy.id"
            />

          </v-slide-group>

          <v-tabs v-model="tab" background-color="transparent" >
            <v-tab
              class="transparent"
              v-for="(item, index) in tabItems"
              :key="index"
            >
              <v-icon small class="mr-3">{{ item.icon }}</v-icon> {{  item.text  }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="transparent">
            <v-tab-item>
              <v-card flat class="transparent">
                <v-card-text>
                  <div v-for="(date, i) in ['MAY 2022', 'JANUARY 2022']" :key="i">
                    <h3 class="mb-5">{{ date }}</h3>

                    <v-alert
                      v-for="(item, index) in ['teal lighten-3', 'blue lighten-3', 'red lighten-3']" :key="index"
                      border="left"
                      colored-border
                      :color="item"
                      elevation="2"
                      class="body-2 mb-5 mt-2"
                    >
                      <div class="d-flex justify-space-between align-center mb-3">
                        <div>
                          <div class="caption">
                            <v-icon small class="mr-2">mdi-format-align-left</v-icon> <strong>Note </strong> by Jordan Hatch
                          </div>
                        </div>
                        <div class="caption">
                          <div>May 2, 2022 at 8:39 PM MST</div>
                        </div>
                      </div>

                      <div class="pl-5 body-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque cum maxime libero explicabo assumenda! Natus obcaecati illo modi ducimus mollitia.
                      </div>
                    </v-alert>

                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="transparent">
                <v-card-text>Emails</v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="transparent">
                <v-card-text>Text</v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="transparent">
                <v-card-text>Notes</v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="transparent">
                <v-card-text>Tasks</v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="transparent">
                <v-card-text>Campaigns</v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="transparent">
                <v-card-text>Attachments</v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
      </v-col>
    </v-row>


    <PolicyDetailsModal 
      :show="policyDetailsModal" 
      @close="closePolicyDetailsModal" 
      :policies="contact.policies" 
      :selected-policy="selectedPolicy"
      :create-new="isNew"
      @create="create"/>

    <ContactModal 
      :contact="contactObj"
      :show="dialog" 
      :type="key"
      @close-modal="closeModal"
      @on-save="reload" />
    
  </div>
</template>

<script>
import PolicyModal from '@/components/Contact/PolicyModal'
import ContactRail from '@/components/Contact/ContactRail'
import ContactModal from '@/components/Contact/ContactModal'
import PolicyDetailsModal from '@/components/Contact/PolicyDetailsModal'
import PolicyCard from '@/components/Policies/PolicyCard'

export default {
    name: 'Contact',
    components: {
        ContactRail,
        ContactModal,
        PolicyModal,
        PolicyDetailsModal,
        PolicyCard
    },
    data() {
        return {
            dialog : false,
            key: '',
            contact: {},
            contactObj: {},
            tab: null,
            tabItems: [
              { icon : 'mdi-apps', text: 'Overview' },
              { icon : 'mdi-email-outline', text: 'Emails' },
              { icon : 'mdi-comment-outline', text: 'Text' },
              { icon : 'mdi-format-align-left', text: 'Notes' },
              { icon : 'mdi-check', text: 'Tasks' },
              { icon : 'mdi-format-list-bulleted ', text: 'Campaigns' },
              { icon : 'mdi-attachment', text: 'Attachments' },
            ],
            policyModal: false,
            policyDetailsModal: false,
            selectedPolicy: null,
            isNew: false
        }
    },
    methods: {
      openModal(param, key) {
        this.dialog = param;
        this.key = key
      },
      create(param) {
        this.isNew = param
      },
      closeModal(param) {
        this.reload();
        this.dialog = param;
        this.key = ''
      },
      closePolicyModal() {
        this.policyModal = false
      },
      openPolicyDetailsModal(param) {
        this.policyDetailsModal = true
        this.selectedPolicy = param
      },
      newPolicy(param) {
        this.policyDetailsModal = true
        this.isNew = true
      },
      closePolicyDetailsModal() {
        this.policyDetailsModal = false
        this.selectedPolicy = null
        this.isNew = false
      },
      async getUser(id) {
        try {
          let contact = await this.$api.contacts.getUser(id);
          let contactPolicy = await this.$api.contacts.getPolicies(id);
          let filteredPolicies = contactPolicy.data

          this.contact = {
            id: contact.data.id,
            ...contact.data.attributes,
            total_premium: (contact.data.attributes.total_premium).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            email_addresses: contact.data.attributes['email_addresses'] ? contact.data.attributes['email_addresses'].map(item => {
              return {
                email: item.email,
                is_primary: item.is_primary ? 1 : 0,
                permission_to_contact: item.permission_to_contact ? 1 : 0,
                type: item.type
              }
            }) : [],
            addresses: contact.data.related?.addresses?.data.length ? contact.data.related?.addresses.data.map(item => {
              return {
                city : item.attributes.city,
                country : item.attributes.country,
                is_primary : item.attributes.is_primary ? 1 : 0,
                ownership : item.attributes.ownership,
                state : item.attributes.state,
                street : item.attributes.street,
                text : item.attributes.text,
                type : item.attributes.type,
                zip_code : item.attributes.zip_code
              }
            }) : [],
            do_not_contact: contact.data.attributes['do_not_contact'] == null ? 0 : 1, 
            phone_numbers: contact.data.attributes.phone_numbers.length ? contact.data.attributes.phone_numbers.map(item => {
              return {
                  phone_number: item.number,
                  extension: item.extension,
                  is_primary: item.is_primary ? 1 : 0,
                  permission_to_contact: item.permission_to_contact ? 1 : 0,
                  type: item.type
              }
            }) : [],
            relationships: contact.data.related?.relationships?.data.map(item => {
              return {
                id: item.attributes.contact.id,
                contact_id: item.attributes.contact.id,
                status: item.attributes.status,
                name: item.attributes.contact.name,
                type: item.attributes.type
              }
            }),
            businesses: contact.data.related?.businesses?.data.map(item => {
              return {
                id: item.id,
                name: item.attributes.name,
                role: item.attributes.role
              }
            }),
            user: contact.data.related?.user?.data ? {
              contact_id: contact.data.related?.user?.data.id,
              text: contact.data.related?.user?.data?.attributes?.name
            } : {
              contact_id: '',
              text: ''
            },
            policies: filteredPolicies.map(policy => {
              return {
                id: policy.id,
                ...policy.attributes,
                carrier: policy.related.carrier?.data,
                owner: policy.related.owner?.data,
                type: policy.related.type?.data,
                user: policy.related.user?.data
              }
            })
          }

          this.contactObj = this.$_.cloneDeep(this.contact)

        } catch (error) {
          console.log(error)
        }
      
      },
      reload() {
        this.getUser(this.$route.params.id);
      }
    },
    mounted() {
      this.getUser(this.$route.params.id);
    }
}
</script>

<style lang="scss" scoped>
.v-slide-group__prev {
  background-color: red !important;
}
</style>>
