<template>
  <v-dialog v-model="dialog" max-width="800" persistent content-class="align-center" scrollable>
        <v-card>
            <v-card-title class="pb-7">
                <div v-if="type == 'main'">Edit Main Details</div>
                <div v-if="type == 'contact_details'">Contact Details</div>
                <div v-if="type == 'personal_details'">Edit Personal Details</div>
                <div v-if="type == 'tags'">Tags</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2"  style="height: 50vh">
                <ValidationObserver ref="obs">
                    <div v-if="type == 'main'">
                        <v-row>
                            <v-col cols="12" sm="3">
                                <ValidationProvider 
                                    rules="required"
                                    name="First Name" v-slot="{ errors }">

                                    <v-text-field 
                                        outlined
                                        dense 
                                        label="First Name"
                                        :error-messages="errors"
                                        v-model="form['first_name']">
                                    </v-text-field>
                                    
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="3">
                                <ValidationProvider 
                                    name="Middle Name" v-slot="{ errors }">

                                    <v-text-field 
                                        outlined
                                        dense 
                                        label="Middle Name"
                                        :error-messages="errors"
                                        v-model="form['middle_name']"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="3">
                                <ValidationProvider 
                                    rules="required"
                                    name="Last Name" v-slot="{ errors }">

                                <v-text-field 
                                    outlined
                                    dense 
                                    label="Last Name"
                                    :error-messages="errors"
                                    v-model="form['last_name']"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="3">
                                <ValidationProvider 
                                    name="Preffered Name" v-slot="{ errors }">

                                    <v-text-field 
                                        outlined
                                        dense 
                                        label="Preffered Name"
                                        :error-messages="errors"
                                        v-model="form['preferred_name']"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="2">
                                <ValidationProvider 
                                    name="Prefix" v-slot="{ errors }">

                                    <v-text-field 
                                        outlined
                                        dense 
                                        label="Prefix"
                                        :error-messages="errors"
                                        v-model="form['prefix']"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="2">
                                <ValidationProvider 
                                    name="Suffix" v-slot="{ errors }">

                                <v-text-field 
                                    persistent-placeholder
                                    outlined
                                    dense 
                                    label="Suffix"
                                    :error-messages="errors"
                                    v-model="form['suffix']"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-menu offset-y :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense 
                                            label="Client Since"
                                            v-model="client_since"></v-text-field>
                                    </template>
                                    <v-date-picker no-title @change="pickDate" v-model="form['client_since']" scrollable></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-divider class="my-2"></v-divider>

                        <div class="d-flex justify-space-between my-5 align-center">
                            <h4>Manage Account Links</h4>
                            
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small outlined color="primary" dark v-bind="attrs" v-on="on">
                                        Add Link <v-icon small class="ml-2">mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                
                                <v-list>
                                    <v-list-item v-for="(link, index) in link_options" :key="index" @click="openLinkMenu(link.value)">
                                        <v-list-item-title>{{ link.text }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>

                        <v-row>
                            <v-col cols="12" sm="5">
                                <div class="grey lighten-3 rounded-lg pa-3">
                                    <h5>PERSONAL</h5>
                                    <div v-for="(item, index) in form['relationships']" :key="'personal'+index"
                                        class="d-flex justify-space-between my-5 align-center">
                                        <div>
                                            <h4>{{ item.name }}</h4>
                                            <p class="ma-0 text-capitalize">{{ item.type }} / {{ findStatus(item.status).text }}</p>
                                        </div>
                                        <div>
                                            <v-menu
                                                bottom
                                                left
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    fab x-small text
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item @click="deleteItem(index, form['relationships'])">
                                                        <v-list-item-title>Delete</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <div class="grey lighten-3 rounded-lg pa-3">
                                    <h5>COMMERCIAL</h5>
                                    <div v-for="(item, index) in form['businesses']" :key="'commercial'+index"
                                        class="d-flex justify-space-between my-5 align-center">
                                        <div>
                                            <h4>{{ item['name'] }}</h4>
                                            <p class="ma-0 text-capitalize">{{ item['role'] }}</p>
                                        </div>
                                        <div>
                                            <v-menu
                                                bottom
                                                left
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    fab x-small text
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item @click="deleteItem(index, form['businesses'])">
                                                        <v-list-item-title>Delete</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="type == 'contact_details'">
                        <v-row class="mt-0">
                            <v-col cols="12" sm="4">
                                <v-switch class="ma-0" :true-value="1" :false-value="0" dense flat v-model="form['do_not_contact']">
                                    <template v-slot:label>
                                        <span>Do not contact</span>
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row>

                        <template>
                            <div v-if="showPhoneNumberError" class="danger--text">* You must have one primary phone number</div>

                            <v-row class="mt-0" v-for="(phone, index) in form.phone_numbers" :key="'phone'+index">

                                <v-col cols="12" sm="3">
                                    <!-- regex: '^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$' -->
                                    <ValidationProvider name="Phone Number" :rules="{
                                        required: true, regex: '^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$' }" v-slot="{ errors }">
                                        <v-text-field 
                                            persistent-placeholder
                                            :error-messages="errors"
                                            outlined
                                            dense 
                                            label="Phone Number"
                                            v-model="phone.phone_number">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-select
                                        persistent-placeholder
                                        outlined
                                        dense
                                        :items="phone_number_types"
                                        label="Type"
                                        v-model="phone.type">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-text-field 
                                        persistent-placeholder
                                        outlined
                                        dense 
                                        label="Extension"
                                        v-model="phone.extension">
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-select
                                        persistent-placeholder
                                        outlined
                                        dense
                                        :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
                                        label="SMS Permissions"
                                        v-model="phone.permission_to_contact">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-select
                                        persistent-placeholder
                                        @change="selectPrimary(index, form.phone_numbers)"
                                        outlined
                                        dense
                                        :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
                                        label="Primary"
                                        v-model="phone.is_primary">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="1" class="d-flex justify-end">
                                    <v-hover
                                        v-slot="{ hover }"
                                    >
                                        <v-btn depressed fab small @click="deleteItem(index, form.phone_numbers)">
                                            <v-icon :color="hover ? 'red' : 'grey'">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-hover>
                                </v-col>
                            </v-row>

                            <v-row class="mt-0">
                                <v-col cols="4">
                                    <span class="mr-3 primary--text text-left text-uppercase cursor-pointer" @click="addPhoneNumber">
                                        <v-icon small color="primary">mdi-plus</v-icon> Add Phone number
                                    </span>
                                </v-col>
                            </v-row>
                        </template>

                        <v-divider class="my-5"></v-divider>

                        <template>

                            <div v-if="showEmailError" class="danger--text">* You must have one primary email</div>

                            <v-row class="mt-0" v-for="(email, index) in form.email_addresses" :key="'email'+index">
                                <v-col cols="12" sm="5">
                                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                        <v-text-field 
                                            persistent-placeholder
                                            :error-messages="errors"
                                            outlined
                                            dense 
                                            label="Email"
                                            v-model="email.email">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                                        <v-select
                                            persistent-placeholder
                                            outlined
                                            :error-messages="errors"
                                            dense
                                            :items="email_types"
                                            label="Type"
                                            v-model="email.type">
                                        </v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-select
                                        persistent-placeholder
                                        @change="selectPrimary(index, form.email_addresses)"
                                        outlined
                                        dense
                                        :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
                                        label="Primary"
                                        v-model="email.is_primary">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="1" class="d-flex justify-end">
                                    <v-hover
                                        v-slot="{ hover }"
                                    >
                                        <v-btn depressed fab small @click="deleteItem(index, form.email_addresses)">
                                            <v-icon :color="hover ? 'red' : 'grey'">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-hover>
                                </v-col>
                            </v-row>

                            <v-row class="mt-0">
                                <v-col cols="12" sm="4">
                                    <v-select
                                        persistent-placeholder
                                        dense
                                        v-model="form['best_time_to_contact']"
                                        :items="best_times_to_contact"
                                        label="Best Time to Contact"
                                        outlined
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row class="mt-0">
                                <v-col>
                                    <span class="mr-3 primary--text text-left text-uppercase cursor-pointer" @click="addEmail">
                                        <v-icon small color="primary">mdi-plus</v-icon> Add Email Address
                                    </span>
                                </v-col>
                            </v-row>
                        </template>

                        <v-divider class="my-5"></v-divider>

                        <template>
                            <div v-if="showAddressError" class="danger--text">* You must have one primary address</div>

                            <v-row class="mt-0 mb-2" v-for="(address, index) in form.addresses" :key="'address'+index" :class="index % 2 == 0 ? 'grey lighten-5' : 'white'">

                                <v-col cols="12" sm="6">
                                    <ValidationProvider name="Street" rules="required" v-slot="{ errors }">
                                        <v-text-field 
                                            persistent-placeholder
                                            :error-messages="errors"
                                            outlined
                                            dense 
                                            label="Street"
                                            v-model="address.street">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="3">
                                    <v-select
                                        persistent-placeholder
                                        outlined
                                        dense
                                        :items="address_types"
                                        label="Type"
                                        v-model="address.type">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-select
                                        persistent-placeholder
                                        @change="selectPrimary(index, form.addresses)"
                                        outlined
                                        dense
                                        item-value="value"
                                        item-text="text"
                                        :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
                                        label="Primary"
                                        v-model="address.is_primary">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="4">
                                    <ValidationProvider name="City" rules="required" v-slot="{ errors }">
                                        <v-text-field 
                                            persistent-placeholder
                                            outlined
                                            :error-messages="errors"
                                            dense 
                                            label="City"
                                            v-model="address.city">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="3">
                                    <ValidationProvider name="State" rules="required" v-slot="{ errors }">
                                        <v-select
                                            persistent-placeholder
                                            :error-messages="errors"
                                            @change="selectCountry(index, address.state)"
                                            outlined
                                            dense
                                            :items="states"
                                            label="State"
                                            v-model="address.state">
                                        </v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-select
                                        persistent-placeholder
                                        outlined
                                        dense
                                        :items="ownership"
                                        label="Own or Rent"
                                        v-model="address.ownership">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <ValidationProvider name="Zip Code" rules="required" v-slot="{ errors }">
                                        <v-text-field 
                                            persistent-placeholder
                                            :error-messages="errors"
                                            outlined
                                            dense 
                                            label="Zip Code"
                                            v-model="address.zip_code">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="1" class="d-flex justify-end">
                                    <v-hover
                                        v-slot="{ hover }"
                                    >
                                        <v-btn depressed fab small @click="deleteItem(index, form.addresses)">
                                            <v-icon :color="hover ? 'red' : 'grey'">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-hover>
                                </v-col>
                                
                            </v-row>

                            <v-row class="mt-0">
                                <v-col>
                                    <span class="mr-3 primary--text text-left text-uppercase cursor-pointer" @click="addAddress">
                                        <v-icon small color="primary">mdi-plus</v-icon> Add Other Addresses
                                    </span>
                                </v-col>
                            </v-row>

                        </template>

                    </div>

                    <div v-if="type == 'personal_details'">
                        <v-row>
                            <v-col cols="12" sm="4">

                                <v-menu offset-y :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense 
                                            label="Birth Date"
                                            v-model="birth_date"></v-text-field>
                                    </template>
                                    <v-date-picker no-title :max="maxDate" @change="pickBirthDate" v-model="form['birthdate']" scrollable></v-date-picker>
                                </v-menu>

                            </v-col>

                            <v-col cols="12" sm="4">
                                <ValidationProvider 
                                    name="Marital Status" v-slot="{ errors }">

                                    <v-select
                                        persistent-placeholder
                                        dense
                                        v-model="form['marital_status']"
                                        :items="marital_statuses"
                                        label="Marital Status"
                                        placeholder="Select Status"
                                        :error-messages="errors"
                                        outlined
                                    ></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <ValidationProvider 
                                    name="Occupation" v-slot="{ errors }">

                                    <v-text-field 
                                        persistent-placeholder
                                        outlined
                                        dense 
                                        label="Occupation"
                                        :error-messages="errors"
                                        v-model="form['occupation']"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="4">
                                <ValidationProvider 
                                    name="Driver License Number" v-slot="{ errors }">

                                    <v-text-field 
                                        persistent-placeholder
                                        outlined
                                        dense 
                                        label="Driver License Number"
                                        :error-messages="errors"
                                        v-model="form['driver_license_number']"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <ValidationProvider 
                                    name="Driver License State" v-slot="{ errors }">

                                    <v-select
                                        persistent-placeholder
                                        dense
                                        v-model="form['driver_license_state']"
                                        :items="states"
                                        label="Driver License State"
                                        placeholder="Select State"
                                        :error-messages="errors"
                                        outlined
                                    ></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-divider class="my-5"></v-divider>

                        <v-row>
                            <v-col cols="12" sm="4">
                                <ValidationProvider 
                                    name="Lead Source" v-slot="{ errors }">

                                    <v-autocomplete
                                        outlined
                                        clearable
                                        dense
                                        :items="lead_types"
                                        v-model="form['lead_source_id']"
                                        label="Lead Source"
                                        placeholder="Select Lead Source"
                                    ></v-autocomplete>

                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <ValidationProvider 
                                    name="Assigned User">
                                    <v-autocomplete 
                                        persistent-placeholder
                                        dense
                                        outlined
                                        chips
                                        return-object
                                        item-text="text"
                                        item-value="contact_id"
                                        no-filter
                                        :items="items"
                                        :search-input.sync="search"
                                        v-model="form['user']"
                                        label="Assigned User"
                                        append-outer-icon="mdi-magnify"
                                        placeholder="Select User">
                                    </v-autocomplete>

                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="type == 'tags'">
                        Tags
                    </div>
                </ValidationObserver>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
                <v-btn depressed @click="closeModal">Cancel</v-btn>
                <v-btn depressed color="teal" class="white--text" :disabled="showEmailError || showPhoneNumberError || showAddressError" @click="save(type)">Save</v-btn>
            </v-card-actions>
        </v-card>

        <ContactPersonalModal :dialog="personalDialog"  @add="addPersonal"/>
        <ContactCommercialModal :dialog="commercialDialog" @add="addPersonal"/>

    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import states from './states'
import moment from 'moment'
import { ValidationObserver } from 'vee-validate';
import ContactPersonalModal from './ContactPersonal'
import ContactCommercialModal from './ContactCommercial'

export default {
    name: "ContactModal",
    props: {
        show: {
            type: Boolean,
            default: false,
            required: true
        },
        type: {
            type: String,
            default: "main",
            required: true
        },
        contact: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            form: {
                addresses: [],
                best_time_to_contact: "",
                birthdate: "",
                client_since: "",
                do_not_contact: 0,
                driver_license_number: "",
                driver_license_state: "",
                email_addresses: [],
                first_name: "",
                last_contact_date: "",
                last_name: "",
                lead_source: "",
                marital_status: "",
                middle_name: "",
                name: "",
                next_renewal_date: "",
                occupation: "",
                phone_numbers: [],
                preferred_name: "",
                prefix: "",
                ssn_last_4: "",
                suffix: "",
                total_policy_count: "",
                total_premium: "",
                user: null,
                businesses: [],
                relationships: [],
                lead_source_id: null
            },
            link_options: [
                { value: "personal", text: "Personal" },
                { value: "commercial", text: "Commercial" },
            ],
            maxDate: new Date().toISOString().substr(0, 10),
            link_option: "",
            personalDialog: false,
            commercialDialog: false,
            contact_search: null,
            contact_label: "",
            contact_type: "",
            search: null,
            businessSearch: null,
            contactEntries: [],
            businessEntries: [],
            client_since: null,
            birth_date: null,
            activeLinkModal: "",
            business_search: null,
            business_type: "",
            states: states.states,
            address_types: [],
            best_times_to_contact: [],
            business_roles: [],
            email_types: [],
            lead_types: [],
            marital_statuses: [],
            phone_number_types: [],
            relationship_statuses: [],
            relationships: [],
            states: [],
            ownership: [
                { text: "Own", value: "own" },
                { text: "Rent", value: "rent" }
            ],
            showEmailError: false,
            showPhoneNumberError: false,
            showAddressError: false,
            showAssigned: false,
            assignedUser: ''
        };
    },
    computed: {
        items() {
            return this.contactEntries.map(entry => {
                return {
                    contact_id: entry.id,
                    text: entry.attributes.name
                };
            });
        },
        businessItems() {
            return this.businessEntries.map(entry => {
                return {
                    id: entry.id,
                    text: entry.attributes.name
                };
            });
        }
    },
    mounted() {
        this.getEnums();
    },
    methods: {
        ...mapActions(["setToast"]),
        selectPrimary(index, array) {
            array.filter((item, idx) => {
                if (idx != index) {
                    item.is_primary = 0;
                }
            });
        },
        selectCountry(index, item) {
            this.form["addresses"][index]["country"] = this.states.find(state => state.value == item).country;
        },
        findStatus(type) {
            return this.relationship_statuses.find(item => item.value == type);
        },
        deleteItem(index, array) {
            array.splice(index, 1);
        },
        changeStateAddress(index, param) {
            this.form.addresses[index]["state"]["long"] = this.states.find(item => item.code == param).name;
        },
        closeModal() {
            this.form.phone_numbers = [];
            this.form.emails = [];
            this.form.addresses = [];
            this.dialog = false;
            this.$emit("close-modal", false);
        },
        async save(type) {
            let form = this.$refs.obs;
            let isValid = await form.validate();
            if (!isValid) {
                return;
            }
            this.form["user"] = {
                id: this.form.user.contact_id,
                ...this.form['user']
            };

            let formParam = this.form;
            let param = {
                id: this.$route.params.id,
                attrs: { ...formParam }
            };
            try {
                let res = await this.$api.contacts.update(param);
                this.closeModal();
                this.$emit("on-save");
                this.setToast({
                    show: true,
                    message: "Saved Successfully",
                    type: "success"
                });
            }
            catch (error) {
                this.setToast({
                    show: true,
                    message: "Something went wrong",
                    type: "error"
                });
                console.log(error);
            }
        },
        openLinkMenu(param) {
            this.link_option = param;
            if (param == "personal") {
                this.personalDialog = true;
            }
            if (param == "commercial") {
                this.commercialDialog = true;
            }
        },
        closeLinkMenu(param) {
            if (param == "personal") {
                this.personalDialog = false;
            }
            if (param == "commercial") {
                this.commercialDialog = false;
            }
        },
        getEnums() {
            this.$api.enums.index().then(res => {
                let address_types = [];
                let best_times_to_contact = [];
                let business_roles = [];
                let email_types = [];
                let lead_types = [];
                let marital_statuses = [];
                let phone_number_types = [];
                let relationship_statuses = [];
                let relationships = [];
                let states = [];
                for (const [key, value] of Object.entries(res.data.phone_number_types)) {
                    phone_number_types.push({
                        value: key,
                        text: value
                    });
                }
                for (const [key, value] of Object.entries(res.data.relationship_statuses)) {
                    relationship_statuses.push({
                        value: key,
                        text: value
                    });
                }
                for (const [key, value] of Object.entries(res.data.states)) {
                    states.push({
                        value: value.short,
                        text: value.name,
                        country: value.country
                    });
                }
                for (const [key, value] of Object.entries(res.data.relationships)) {
                    relationships.push({
                        value: key,
                        text: `${value.left} - ${value.right}`
                    });
                }
                for (const [key, value] of Object.entries(res.data.address_types)) {
                    address_types.push({
                        value: key,
                        text: value.replace("_", " ")
                    });
                }
                for (const [key, value] of Object.entries(res.data.best_times_to_contact)) {
                    best_times_to_contact.push({
                        value: key,
                        text: value
                    });
                }
                for (const [key, value] of Object.entries(res.data.business_roles)) {
                    business_roles.push({
                        value: key,
                        text: value
                    });
                }
                for (const [key, value] of Object.entries(res.data.email_types)) {
                    email_types.push({
                        value: key,
                        text: value
                    });
                }
                for (const [key, value] of Object.entries(res.data.lead_types)) {
                    lead_types.push({
                        value: value.id,
                        text: value.name
                    });
                }
                for (const [key, value] of Object.entries(res.data.marital_statuses)) {
                    marital_statuses.push({
                        value: key,
                        text: value
                    });
                }
                this.address_types = address_types;
                this.best_times_to_contact = best_times_to_contact;
                this.business_roles = business_roles;
                this.email_types = email_types;
                this.lead_types = lead_types;
                this.marital_statuses = marital_statuses;
                this.phone_number_types = phone_number_types;
                this.relationship_statuses = relationship_statuses;
                this.relationships = relationships;
                this.states = states;
            });
        },
        pickDate(date) {
            this.client_since = moment(date).format("MM-DD-YYYY");
        },
        pickBirthDate(date) {
            this.birth_date = moment(date).format("MM-DD-YYYY");
        },
        fetchEntriesDebounced(param) {
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchContactEntries(param);
            }, 500);
        },
        fetchBusinessEntriesDebounced(param) {
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchBusinessEntries(param);
            }, 500);
        },
        fetchContactEntries(param) {
            this.$api.contacts.index({ "search": param }).then((res) => {
                this.contactEntries = res.data;
            });
        },
        fetchBusinessEntries(param) {
            this.$api.business.index({ "search": param }).then((res) => {
                this.businessEntries = res.data;
            });
        },
        async addPersonal() {
            let valid = await this.$refs.personalLink.validate();

            if(!valid) {
                return
            }

            let obj = {
                contact_id: this.contact_search.contact_id,
                name: this.contact_search.text,
                type: this.contact_label,
                status: this.contact_type
            };
            this.contact["relationships"].push(obj);
            this.closeLinkMenu(this.link_option);
            this.link_option = "";
            this.contact_search = null;
            this.contact_type = null;
            this.contact_label = null;

            //this.$nextTick(() => this.$refs.personalLink.reset())
            
        },
        addBusiness() {
            let obj = {
                id: this.business_search.id,
                name: this.business_search.text,
                role: this.business_type
            };
            this.contact["businesses"].push(obj);
            this.closeLinkMenu(this.link_option);
            this.link_option = "";
            this.business_search = null;
            this.business_type = null;
        },
        addPhoneNumber() {
            let primary = 0;
            if (!this.form.phone_numbers.length) {
                primary = 1;
            }
            this.form.phone_numbers.push({
                phone_number: null,
                extension: null,
                type: null,
                is_primary: primary,
                permission_to_contact: 0
            });
        },
        addAddress() {
            let primary = 0;
            if (!this.form.addresses.length) {
                primary = 1;
            }
            this.form.addresses.push({
                city: null,
                country: null,
                is_primary: primary,
                ownership: null,
                street: "",
                zip_code: null,
                state: null,
                type: null
            });
        },
        addEmail() {
            let primary = 0;
            if (!this.form.email_addresses.length) {
                primary = 1;
            }
            this.form.email_addresses.push({
                email: null,
                is_primary: primary,
                permission_to_contact: 0,
                type: null
            });
        },
    },
    watch: {
        search(val) {
            if (!val) {
                return;
            }
            this.fetchEntriesDebounced(val);
        },
        businessSearch(val) {
            if (!val) {
                return;
            }
            this.fetchBusinessEntriesDebounced(val);
        },
        "form.email_addresses": {
            handler: function (newVal) {
                if (newVal) {
                    let check = [];
                    newVal.forEach(item => {
                        if (item.is_primary && !check.length) {
                            check.push(item);
                        }
                    });
                    if (!check.length) {
                        this.showEmailError = true;
                    }
                    else {
                        this.showEmailError = false;
                    }
                }
            },
            deep: true
        },
        "form.addresses": {
            handler: function (newVal) {
                if (newVal) {
                    let check = [];
                    newVal.forEach(item => {
                        if (item.is_primary && !check.length) {
                            check.push(item);
                        }
                    });
                    if (!check.length) {
                        this.showAddressError = true;
                    }
                    else {
                        this.showAddressError = false;
                    }
                }
            },
            deep: true
        },
        "form.phone_numbers": {
            handler: function (newVal) {
                if (newVal) {
                    let check = [];
                    newVal.forEach(item => {
                        if (item.is_primary && !check.length) {
                            check.push(item);
                        }
                    });
                    if (!check.length) {
                        this.showPhoneNumberError = true;
                    }
                    else {
                        this.showPhoneNumberError = false;
                    }
                }
            },
            deep: true
        },
        show(newVal) {
            this.dialog = newVal;
            if (newVal) {
                Object.keys(this.form).forEach(key => {
                    this.form[key] = this.contact[key];
                });

                if(this.form["birthdate"] == null) {
                    this.form["birthdate"] = moment()
                }

                this.client_since = this.form["client_since"] ? moment(this.form["client_since"]).format("MM-DD-YYYY") : "";
                this.birth_date = this.form["birthdate"] ? moment(this.form["birthdate"]).format("MM-DD-YYYY") : "";
                this.form["lead_source_id"] = this.lead_types.find((item) => item.text == this.form["lead_source"]).value || null;
                this.items.push(this.form['user'])
            }
        },
        birth_date(newVal) {
            this.birth_date = moment(newVal).format("MM-DD-YYYY");
            this.form["birthdate"] = moment(newVal).format("YYYY-MM-DD");
        }
    },
    components: { ValidationObserver, ContactPersonalModal, ContactCommercialModal }
}
</script>