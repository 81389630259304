var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5 fill-height overflow-auto",staticStyle:{"height":"50vh"}},[_c('h3',[_vm._v("Add New Policy")]),_c('div',{staticClass:"mt-10"},[_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_vm._v(" Business: ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-radio-group',{staticClass:"mt-0 pa-0 ba-label",attrs:{"dense":"","column":false},model:{value:(_vm.form.is_renewal),callback:function ($$v) {_vm.$set(_vm.form, "is_renewal", $$v)},expression:"form.is_renewal"}},[_c('v-radio',{staticClass:"mr-5",attrs:{"label":"Existing","value":false}}),_c('v-radio',{attrs:{"label":"New","value":true}})],1)],1)],1)],1),_c('div',[(_vm.form.is_renewal)?_c('div',{attrs:{"no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_vm._v(" Is this a new opportunity? ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-radio-group',{staticClass:"mt-0 pa-0 ba-label",attrs:{"dense":"","column":false},model:{value:(_vm.form.is_new),callback:function ($$v) {_vm.$set(_vm.form, "is_new", $$v)},expression:"form.is_new"}},[_c('v-radio',{staticClass:"mr-5",attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1)],1)],1)],1):_c('div',[_c('ValidationObserver',{ref:"policyForm"},[_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"5"}},[_vm._v(" Policy Type: ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Policy Type","rules":"required","vid":"policy_type_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"outlined":"","dense":"","items":_vm.policyTypes,"item-value":"id","item-text":"text"},model:{value:(_vm.form.policy_type_id),callback:function ($$v) {_vm.$set(_vm.form, "policy_type_id", $$v)},expression:"form.policy_type_id"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"5"}},[_vm._v(" Policy Number: ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Policy Number","rules":"required","vid":"policy_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.form.policy_number),callback:function ($$v) {_vm.$set(_vm.form, "policy_number", $$v)},expression:"form.policy_number"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"5"}},[_vm._v(" Effective Date ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-menu',{ref:"start_date",attrs:{"close-on-content-click":false,"return-value":_vm.form.start_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "start_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "start_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Policy Number","rules":"required","vid":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors,"label":"Select Date","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.maxDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.start_date = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.start_date.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"5"}},[_vm._v(" Term Length ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Term Length","rules":"required","vid":"terms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"outlined":"","dense":"","items":_vm.terms,"item-value":"value","item-text":"text"},model:{value:(_vm.form.terms),callback:function ($$v) {_vm.$set(_vm.form, "terms", $$v)},expression:"form.terms"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"5"}},[_vm._v(" Carrier: ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Carrier","rules":"required","vid":"carrier_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"outlined":"","dense":"","items":_vm.carriers,"item-value":"id","item-text":"text"},model:{value:(_vm.form.carrier_id),callback:function ($$v) {_vm.$set(_vm.form, "carrier_id", $$v)},expression:"form.carrier_id"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"5"}},[_vm._v(" Premium ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Premium","rules":"required","vid":"premium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.form.premium),callback:function ($$v) {_vm.$set(_vm.form, "premium", $$v)},expression:"form.premium"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"5"}},[_vm._v(" Commision Split ")]),_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Commision Split","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.form.commision),callback:function ($$v) {_vm.$set(_vm.form, "commision", $$v)},expression:"form.commision"}})]}}])})],1)],1)],1)],1)]),_c('div',{staticClass:"sr-only mt-10"})])}
var staticRenderFns = []

export { render, staticRenderFns }