<template>
    <v-dialog v-model="dialog" max-width="800" persistent content-class="align-center" scrollable>
        <v-card>
            <v-card-title class="pb-7">
                Policy Details
            </v-card-title>
            <v-divider class="mb-5"></v-divider>
            <v-card-text style="height: 50vh">
                <v-row class="fill-height">
                    <v-col cols="12" sm="3">
                        Add New Policy
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="12" sm="9">
                        <h3>Add New Policy</h3>

                        <v-row>
                            <v-col cols="12" sm="4">Business</v-col>
                            <v-col cols="12" sm="8">
                                <v-radio-group v-model="isNewOpportunity" :column="false">
                                    <v-radio label="Existing" value="false"></v-radio>
                                    <v-radio label="New" value="true"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
                <v-btn depressed @click="$emit('close')">Cancel</v-btn>
                <v-btn depressed color="teal" class="white--text" @click="save()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'PolicyModal',
    props: {
        show: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            isNewOpportunity: false,
            isExisting: false
        }
    },
    methods: {
        async save() {
            let param = {
                policy_number: '21323123',
                is_renewal : false,
                start_date: this.$moment().format("Y-m-d"),
                terms: 12,
                premium: 2133,
                policy_type_id: 12321,
                contact_id: 12321,
                carrier_id: 21321
            }

            try {
                await this.$api.policies.create(param);
            } catch (error) {
                console.log(error);
            }
        }
    },
    watch: {
        show(newVal) {
            this.dialog = newVal;
        }
    }
}
</script>

<style>

</style>