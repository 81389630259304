<template>
  <div>
    <v-row justify="center">
        <v-dialog v-model="dialog" max-width="930" persistent content-class="align-center" scrollable>
            <v-card style="height: 70vh" class="overflow-hidden">
                <v-card-title class="pb-7">
                    <div class="d-flex align-center justify-space-between mb-3" style="width: 100%">
                        <h5>Policy Details</h5>
                        <v-btn text fab x-small @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-card-title>
                <v-divider class="mb-5"></v-divider>
                <v-card-text>
                    <v-row class="overflow-hidden fill-height">
                        <v-col cols="12" sm="4" class="overflow-auto overflow-x-hidden fill-height">

                            <div v-if="newlyAddedPolicy !== null">
                                <h4 class="mb-5 d-flex align-center justify-space-between">
                                    <span>New</span>
                                </h4>
                                <PolicyCard v-if="newlyAddedPolicy !== null" :policy="newlyAddedPolicy" @select="select"
                                    :selected="selectedPolicy && selected.id == policy.id && !createNew"
                                    :disabled="selectedPolicy && selected.id != policy.id && mode == 'edit' || newPolicy" />
                            </div>
                            
                            <div>
                                <h4 class="mb-5 d-flex align-center justify-space-between">
                                    <span>Active</span>
                                    <v-btn text color="teal" depressed @click="createNewPolicy" v-if="newPolicy == false">Add New Policy</v-btn>
                                </h4>
                                <PolicyCard 
                                    v-for="(policy, index) in activePolicies" 
                                    :policy="policy" :key="index"
                                    @select="select"
                                    :selected="selectedPolicy && selected.id == policy.id && !createNew"
                                    :disabled="selectedPolicy && selected.id != policy.id && mode == 'edit' || newPolicy" />
                            </div>

                            
                            <div>
                                <h4 class="mb-5">
                                    <span>Pending</span>
                                </h4>
                                <PolicyCard 
                                    v-for="(policy, index) in pendingPolicies" 
                                    :policy="policy" :key="index + `pending`"
                                    @select="select"
                                    :selected="selectedPolicy && selected.id == policy.id && !createNew"
                                    :disabled="selectedPolicy && selected.id == policy.id && mode == 'edit' || newPolicy"  />
                            </div>

                            <div>
                                <h4 class="mb-5">
                                    <span>Inactive</span>
                                </h4>
                                <PolicyCard 
                                    v-for="(policy, index) in inactivePolicies" 
                                    :policy="policy" :key="index + `inactive`"
                                    @select="select"
                                    :selected="selectedPolicy && selected.id == policy.id && !createNew"
                                    :disabled="selectedPolicy && selected.id == policy.id && mode == 'edit' || newPolicy" />
                            </div>

                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" sm="8">
                            <div v-if="newPolicy">

                                <PolicyCreateForm 
                                    :carriers="carriers"
                                    :policy-types="policyTypes"
                                    :saving="isSaving" 
                                    @saved="newPolicySaved"/>

                            </div>
                            <div v-else>
                                <div class="d-flex align-center justify-end">
                                    <v-menu offset-y v-if="mode == 'view'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small outlined color="primary" dark v-bind="attrs" v-on="on">
                                                Actions <v-icon small class="ml-2">mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </template>
                                        
                                        <v-list>
                                            <v-list-item class="cursor-pointer" @click="edit">
                                                <v-list-item-title>Edit</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer" @click="cancelPolicy">
                                                <v-list-item-title>Cancel Policy</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer">
                                                <v-list-item-title>Create Service Opportunity</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer" @click="confirmDelete">
                                                <v-list-item-title class="danger--text">Delete</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>


                                    <div v-if="mode == 'edit'">
                                        <v-btn small depressed class="mr-3" @click="mode = 'view'">Cancel</v-btn>
                                        <v-btn small depressed color="teal" class="white--text">Save</v-btn>
                                    </div>
                                </div>

                                <v-divider class="my-3"></v-divider>

                                <div v-if="selected">
                                    <div v-if="mode == 'view'">
                                        <PolicyView :policy="selected"/>
                                    </div>

                                    <div v-if="mode == 'edit'">
                                       <div class="overflow-auto" style="height: 50vh">
                                            <ValidationObserver ref="policy">
                                                <div class="mb-5">
                                                    <v-row class="mt-0">
                                                        <v-col cols="12" class="py-0 mb-8"><h3>{{ selected.type.attributes.name }}</h3></v-col>
                                                        <v-col cols="4">
                                                            Assigned Owner:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            TODO
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Main Contact:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            TODO
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            New/Existing Policy:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            TODO
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0" align-content="center">
                                                        <v-col cols="4">
                                                            Policy Status:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <ValidationProvider name="Policy Status" rules="required" v-slot="{ errors }">
                                                                <v-select
                                                                    :error-messages="errors"
                                                                    outlined
                                                                    dense
                                                                    :items="policy_statuses"
                                                                    v-model="policyForm.status">
                                                                </v-select>
                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Policy Sub Status:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <v-select
                                                                outlined
                                                                dense
                                                                :items="policy_statuses"
                                                                v-model="policyForm.status">
                                                            </v-select>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Policy Type:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <ValidationProvider name="Policy Type" rules="required" v-slot="{ errors }">
                                                                <v-select
                                                                    :error-messages="errors"
                                                                    outlined
                                                                    dense
                                                                    :items="policyTypes"
                                                                    v-model="policyForm.type.name">
                                                                </v-select>
                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>
                                                </div>

                                                <div class="mb-5">
                                                    <v-row class="mt-0">
                                                        <v-col cols="12" class="py-0 mb-8 mt-5"><h3>Policy Details</h3></v-col>
                                                        <v-col cols="4">
                                                            Additional Insured:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <div class="font-weight-bold">No additional insured found.</div>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Commission:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <div class="font-weight-bold">--</div>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Carrier:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <v-select
                                                                outlined
                                                                dense
                                                                :items="carriers"
                                                                v-model="policyForm.carrier.name">
                                                            </v-select>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Effective Date: 
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <v-menu offset-y :close-on-content-click="false">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <ValidationProvider name="Effective Date" rules="required" v-slot="{ errors }">
                                                                        <v-text-field
                                                                            class="mb-2"
                                                                            :error-messages="errors"
                                                                            append-icon="mdi-calendar"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            outlined
                                                                            dense 
                                                                            v-model="effective_date"></v-text-field>
                                                                    </ValidationProvider>
                                                                </template>
                                                                <v-date-picker no-title @change="pickEffectiveDate" v-model="policyForm.start_date" scrollable></v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Insured: 
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <div class="font-weight-bold">{{ selected.user != null ? selected.user.attributes.name  : 'NULL'}}</div>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Policy Number:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <ValidationProvider name="Policy Number" rules="required" v-slot="{ errors }">
                                                                <v-text-field 
                                                                    :error-messages="errors"
                                                                    outlined
                                                                    dense 
                                                                    v-model="policyForm.policy_number">
                                                                </v-text-field>
                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Premium Amount($): 
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <v-text-field 
                                                                outlined
                                                                dense 
                                                                v-model="policyForm.premium">
                                                                <v-icon
                                                                    slot="prepend"
                                                                    color="grey"
                                                                    size="medium"
                                                                    >
                                                                    mdi-currency-usd
                                                                </v-icon>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-0">
                                                        <v-col cols="4">
                                                            Term Length:
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <v-select
                                                                outlined
                                                                dense
                                                                :items="terms"
                                                                v-model="policyForm.terms">
                                                            </v-select>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </ValidationObserver>

                                            <div class="mb-5">
                                                <v-row class="mt-0">
                                                    <v-col cols="12" class="py-0 mb-8 mt-5"><h3>Notes History</h3></v-col>
                                                </v-row>

                                                <v-row class="mt-0">
                                                    <v-col cols="12">
                                                        <v-alert
                                                            v-for="(item, index) in 2" :key="index"
                                                            border="left"
                                                            colored-border
                                                            color="blue lighten-3"
                                                            elevation="2"
                                                            class="body-2 mb-5 mt-2"    
                                                        >
                                                            <div class="d-flex justify-space-between align-center mb-3">
                                                                <div>
                                                                <div class="caption">
                                                                    <v-icon small class="mr-2">mdi-format-align-left</v-icon> <strong>Note </strong> by Jordan Hatch
                                                                </div>
                                                                </div>
                                                                <div class="caption">
                                                                <div>May 2, 2022 at 8:39 PM MST</div>
                                                                </div>
                                                            </div>

                                                            <div class="pl-5 body-2">
                                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque cum maxime libero explicabo assumenda! Natus obcaecati illo modi ducimus mollitia.
                                                            </div>
                                                        </v-alert>
                                                    </v-col>
                                                </v-row>
                                            </div>

                                            <div class="mb-5">
                                                <v-row class="mt-0">
                                                    <v-col cols="12" class="py-0 mb-8"><h3>Service Details</h3></v-col>
                                                </v-row>
                                            </div>

                                            <div class="mb-5">
                                                <v-row class="mt-0">
                                                    <v-col cols="12" class="py-0 mb-8"><h3>Attachments</h3></v-col>
                                                </v-row>
                                            </div>
                                       </div>
                                    </div>
                                </div>

                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="newPolicy" class="d-flex justify-end">
                    <v-btn depressed text color="grey" @click="cancelCreate">Cancel</v-btn>
                    <v-btn depressed text color="teal" @click="createPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="cancelPolicyDialog" max-width="600" persistent content-class="align-center" scrollable>
            <v-card>
                <ValidationObserver ref="obs" v-slot="{ invalid }">
                    <v-card-title class="">
                        <div class="d-flex align-center justify-space-between mb-3" style="width: 100%">
                            <h6>Cancel Policy</h6>
                        </div>
                    </v-card-title>
                    <v-divider class="mb-3"></v-divider>
                    <v-card-text>
                        
                        <h3>Are you sure you want to cancel this policy?</h3>
                        <p class="mb-10">Please complete all required questions below. [how this shows up on reports..]</p>

                            <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <ValidationProvider name="Effective Date" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                            class="mb-2"
                                            :error-messages="errors"
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense 
                                            label="What day is the cancellation effective date?"
                                            v-model="cancellation_date"></v-text-field>
                                    </ValidationProvider>
                                </template>
                                <v-date-picker no-title @change="pickDate" v-model="cancellation.date" scrollable></v-date-picker>
                            </v-menu>


                            <ValidationProvider name="Cancellation reason" rules="required" v-slot="{ errors }">
                                <v-select
                                    class="mb-2"
                                    :error-messages="errors"
                                    dense
                                    v-model="cancellation.reason"
                                    :items="reasons"
                                    label="Cancellation reason"
                                    placeholder="Select cancellation reason"
                                    outlined
                                ></v-select>
                            </ValidationProvider>

                            <v-text-field
                                class="mb-2"
                                outlined
                                dense 
                                label="What is the new carrier? (optional)"
                                v-model="cancellation.new_carrier"></v-text-field>

                            <v-text-field
                                class="mb-2"
                                outlined
                                dense 
                                label="Term lenth of new policy: (optional)"
                                v-model="cancellation.term_length"></v-text-field>

                            <v-text-field
                                class="mb-2"
                                outlined
                                dense 
                                label="Premium of new policy: (optional)"
                                v-model="cancellation.new_premium"></v-text-field>

                    </v-card-text>
                    <v-card-actions class="d-flex justify-end">
                        <v-btn depressed text color="grey" @click="closeCancelPolicy">No, Cancel</v-btn>
                        <v-btn :disabled="invalid" depressed text color="teal" @click="cancelPolicyYes">Yes, Cancel Policy</v-btn>
                    </v-card-actions>

                </ValidationObserver>
            </v-card>
        </v-dialog>

        <v-dialog v-model="changesMade" max-width="600" persistent content-class="align-center" scrollable>
            <v-card>
                <v-card-title>
                    <div class="d-flex align-center justify-space-between mb-3" style="width: 100%">
                        <h6>Cancel</h6>
                    </div>
                </v-card-title>
                <v-divider class="mb-3"></v-divider>
                
                <v-card-text>There are unsaved changes. If you would like to save changes, press the ‘Keep Editing’ button.
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn depressed text color="grey" @click="closeChangesMadeModal">Close without saving</v-btn>
                    <v-btn depressed text color="teal" @click="changesMade = false">Keep Editing</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmDeleteModal" max-width="600" persistent content-class="align-center" scrollable>
            <v-card>
                <v-card-title>
                    <div class="d-flex align-center justify-space-between mb-3" style="width: 100%">
                        <h6>Delete Policy</h6>
                    </div>
                </v-card-title>
                <v-divider class="mb-3"></v-divider>
                
                <v-card-text>Are you sure you want to delete this policy? This action can not be undone..</v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn depressed text color="grey" @click="confirmDeleteModal = false">No, Cancel</v-btn>
                    <v-btn depressed text color="danger" @click="deletePolicy">Yes, Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
  </div>
</template>

<script>
import carriers from '@/api/carriers'
import PolicyCard from '@/components/Policies/PolicyCard'
import PolicyCreateForm from '../Policies/PolicyCreateForm'
import PolicyView from '../Policies/PolicyView'
import moment from 'moment'
import { ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

export default {
    name: 'PolicyDetailsModal',
    components: {
    PolicyCard,
    ValidationObserver,
    PolicyCreateForm,
    PolicyView
},
    data() {
        return {
            dialog: false,
            cancelPolicyDialog: false,
            changesMade: false,
            selected: null,
            mode: 'view',
            newPolicy: false,
            policyList: [],
            cancellation: {
                date: null,
                reason: null,
                new_carrier: '',
                term_length: '',
                new_premium: ''
            },
            policy: {
                policy_number: '',
                is_renewal : false,
                start_date: new Date().toISOString().substr(0, 10),
                terms: 6,
                premium: 0,
                policy_type_id: null,
                contact_id: '98974660-609e-4ccd-9b3c-124c312bc9fd',
                carrier_id: null,
                stage: null
            },
            effective_date_create: '',
            cancellation_date: null,
            effective_date: null,
            confirmDeleteModal: false,
            reasons: [
                'Agency Cancellation',
                'Failure to comply with recommendations',
                'Material change in risk',
                'Material misrepresentation',
                'Moral risk',
                'Non-Pay',
                'Risk no longer fits appetite',
                'Underwriting Cancellation',
                'Other',
                'Insured: Moved out of state',
                'Insured: Price',
                'Insured: Property/Vehicle/Business sold',
                'Insured: Requested BOR change',
                'Insured: Service/Experience',
                'Insured: Other',
                'Non-Renewal: Agency non-renewal',
                'Non-Renewal: Insured non-renewal',
                'Non-Renewal: Underwriting non-renewal',
                'Non-Renewal: Other'
            ],
            stages: [
                'Appointment Scheduled',
                'Working',
                'Quote Ready',
                'Quote Sent',
                'Won',
                'Lost'
            ],
            policyForm: null,
            policyFormCopy: null,
            policyTypes: [],
            carriers: [],
            policy_statuses: [
                { text: 'Active', value: 'active' },
                { text: 'Inactive', value: 'inactive' },
                { text: 'Pending', value: 'pending' }
            ],
            terms: [
                { text: '6 months', value: 6 },
                { text: '12 months', value: 12 }
            ],
            isSaving: false,
            newlyAddedPolicy: null
        }
    },
    props: {
        show: {
            default: false,
            type: Boolean,
        },
        createNew: {
            default: false,
            type: Boolean,
        },
        policies: {
            default: () => []
        },
        selectedPolicy: {
            default: () => {}
        }
    },
    computed: {
        activePolicies() {
            let policies = this.$_.cloneDeep(this.policyList)
            return policies?.length ? policies.filter(item => item.status == 'active') : []
        },
        inactivePolicies() {
            let policies = this.$_.cloneDeep(this.policyList)
            return policies?.length ? policies.filter(item => item.status == 'inactive') : []
        },
        pendingPolicies() {
            let policies = this.$_.cloneDeep(this.policyList)
            return policies?.length ? policies.filter(item => item.status == 'pending') : []
        }
    },
    methods: {
        ...mapActions(['setToast']),
        async createPolicy() {
            this.isSaving = true
        },
        newPolicySaved(param) {
            console.log(param)
            this.newlyAddedPolicy = param
            this.selected = param
            this.isSaving = false
            this.newPolicy = false
        },
        confirmDelete() {
            this.confirmDeleteModal = true
        },
        deletePolicy() {
            console.log(this.selected)
            this.$api.policies.cancel(this.selected)
            this.confirmDeleteModal = false
        },
        createNewPolicy() {
            this.newPolicy = true
            this.$emit('create', true)
        },
        cancelCreate() {
            this.closeDialog()
        },
        pickDate(date) {
            this.cancellation_date = moment(date).format("MM-DD-YYYY");
        },
        pickDateCreate(date) {
            console.log(date)
            this.effective_date_create = moment(date).format("MM-DD-YYYY");
        },
        pickEffectiveDate(date) {
            this.effective_date = moment(date).format("MM-DD-YYYY");
        },
        getEnums() {
            const allPromise = Promise.all([
                this.$api.policies.getPolicyTypes(), this.$api.policies.getCarriers()
            ])

            allPromise.then(values => {
                this.carriers = values[1]?.data.map(item => {
                    return {
                        text: item.attributes.display,
                        id: item.id,
                        value: item.attributes.name
                    }
                })

                this.policyTypes = values[0]?.data.map(item => {
                    return {
                        text: item.attributes.name,
                        id: item.id,
                        value: item.attributes.name
                    }
                })
            }).catch(err => {
                console.log(err)
            })

            // let test = await this.$api.policies.getPolicyTypes();
            // console.log(test);
        },
        closeChangesMadeModal() {
            this.changesMade = false
            this.mode = 'view'

            this.policyForm = {...this.policyFormCopy}
        },
        cancelPolicy() {
            this.cancelPolicyDialog = true
        },
        async cancelPolicyYes() {
            let valid = await this.$refs.obs.validate();

            if(!valid) {
                return
            }

            let cancellations = {
                cancellation_date: this.cancellation_date,
                cancellation_reason: this.cancellation.reason
            }

            let param = {
                id: this.selected.id,
                attrs: cancellations
            };

            try {
                await this.$api.policies.cancel(param);

                this.setToast({
                    show: true,
                    message: 'Policy successfully cancelled!',
                    type: 'success'
                })

                this.closeCancelPolicy()
            }
            catch (error) {

                this.setToast({
                    show: true,
                    message: 'Something went wrong',
                    type: 'error'
                })

                console.log(error);
            }
        },
        closeCancelPolicy() {
            this.cancelPolicyDialog = false
            this.cancellation_date = ''
            this.cancellation.new_carrier = null
            this.cancellation.term_length = null
            this.cancellation.new_premium = null
            this.cancellation.reason = null

            this.$refs.obs.reset();
        },
        async cancelEdit() {
            let obs = this.$refs.policy

            if(obs.flags.changed) {
                this.changesMade = true
            } else {
                this.mode = 'view'
            }
        },
        closeDialog() {
            this.dialog = false;
            this.$emit('close')
            this.mode = 'view'
            this.newPolicy = false
            this.$emit('create', false)
        },
        select(param) {
            this.selected = param
        },
        edit() {
            this.mode = 'edit'
        }
    },
    watch: {
        createNew(newVal) {
            this.newPolicy = newVal
        },
        show(newVal) {
            if(newVal) {
                this.dialog = newVal

                if(!this.carriers.length && !this.policyTypes.length) {
                    this.getEnums()
                }
            }
        },
        selectedPolicy(newVal) {
            if(newVal) {
                this.selected = newVal
                this.policyForm = {...this.$_.cloneDeep(this.selected)}

                this.policyForm.owner = {
                    id: this.policyForm.owner.id,
                    name: this.policyForm.owner?.attributes.name
                }

                this.policyForm.carrier = {
                    id: this.policyForm.carrier.id,
                    name: this.policyForm.carrier?.attributes.name
                }

                this.policyForm.type = {
                    id: this.policyForm.type.id,
                    name: this.policyForm.type?.attributes.name
                }

                this.policyForm.user = {
                    id: this.policyForm?.user?.id,
                    name: this.policyForm.user?.attributes.name
                }

                this.policyFormCopy  = {...this.$_.cloneDeep(this.policyForm)}

                this.effective_date = this.policyForm["start_date"] ? moment(this.policyForm["start_date"]).format("MM-DD-YYYY") : "";
            }
        },
        policies(newVal) {
            if(newVal) {
                this.policyList = newVal
            }
        }
    }
}
</script>

<style>

</style>