<template>
  <div>
    <div data-attr="contact_details">
        <div class="d-flex justify-space-between align-center mb-3">
            <div>
                <h4 class="text-uppercase">Contact Details</h4>
            </div>
            <div>
                <v-btn fab plain x-small @click="edit('contact_details')">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </div>
        </div>
        
        <v-row no-gutters class="my-2">
            <v-col sm="6">Do Not Contact</v-col>
            <v-col sm="6">
                <v-chip v-if="contactData['do_not_contact'] == 1" outlined color="danger" small>DNC</v-chip>
                <v-chip v-else outlined color="success" small>OK</v-chip>
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="12" class="mt-2">
                <v-row no-gutters v-if="primaryAddress.length">
                    <v-col sm="6">Primary Address</v-col>
                    <v-col sm="6">
                        <v-row v-for="(primary, index) in primaryAddress" :key="`primary`+index">
                            <v-col cols="12" class="font-weight-bold">{{ primary['street'] }} <br> {{ primary['city'] }} {{ primary['state'] }} {{ primary['zip_code'] }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col sm="12" class="mt-2">
                <v-row no-gutters v-if="mailingAddress.length">
                    <v-col sm="6">Mailing Address</v-col>
                    <v-col sm="6">
                        <v-row v-for="(mailing, index) in mailingAddress" :key="`mailing`+index">
                            <v-col cols="12" class="font-weight-bold">{{ mailing['street'] }} <br> {{ mailing['city'] }} {{ mailing['state'] }} {{ mailing['zip_code'] }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col sm="12" class="mt-2">
                <v-row no-gutters v-if="otherAddresses.length">
                    <v-col sm="6">Other Addresses</v-col>
                    <v-col sm="6">
                        <v-row v-for="(otheraddress, index) in otherAddresses" :key="`mailing`+index">
                            <v-col cols="12" class="font-weight-bold">{{ otheraddress['street'] }} <br> {{ otheraddress['city'] }} {{ otheraddress['state'] }} {{ otheraddress['zip_code'] }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Phone</v-col>
            <v-col sm="6">

                <template>
                    <div v-if="phones">
                        <div v-for="(phone, index) in contactData['phone_numbers']" :key="'phone'+index" class="mb-2">
                            <span class="font-weight-black" v-if="phone.is_primary == 1">
                                <span v-if="phone.extension">({{ phone['extension'] }})</span> {{ phone['phone_number'] }} <span class="text-capitalize font-weight-light" v-if="phone['type']">- {{ phone['type'] }}</span>
                            </span>
                        </div>
                    </div>

                    <div v-if="showMorePhones">
                        <div v-for="(phone, index) in contactData['phone_numbers']" :key="'phone'+index" class="mb-2">
                            <span class="font-weight-black" v-if="phone.is_primary != 1">
                                ({{ phone['extension'] }}) {{ phone['phone_number'] }} <span class="text-capitalize font-weight-light" v-if="phone['type']"> - {{ phone['type'] }}</span>
                            </span>
                            
                        </div>
                    </div>

                    <div class="mt-1" @click="showMorePhones = !showMorePhones" v-if="phones && phones > 1" style="cursor: pointer">
                        <div v-if="!showMorePhones">+ {{ contactData['phone_numbers'].length - 1 }} more</div>
                        <div v-else>show less</div>
                    </div>
                </template>
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Best Time to Contact</v-col>
            <v-col sm="6" class="text-capitalize font-weight-black">{{ contactData['best_time_to_contact'] }}</v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Email Addresses</v-col>
            <v-col sm="6">

                <template>
                    <div v-if="emails">
                        <div v-for="(email, index) in contactData['email_addresses']" :key="'email'+index" class="mb-2">
                            <span class="font-weight-black text-decoration-underline" v-if="email.is_primary == 1">{{ email['email'] }}</span>
                        </div>
                    </div>

                    <div v-if="showMoreEmail">
                        <div v-for="(email, index) in contactData['email_addresses']" :key="'email'+index" class="mb-2">
                            <span class="font-weight-black text-decoration-underline" v-if="email.is_primary != 1">{{ email['email'] }}</span>
                        </div>
                    </div>

                    <div class="mt-1" @click="showMoreEmail = !showMoreEmail" v-if="emails && emails > 1" style="cursor: pointer">
                        <div v-if="!showMoreEmail">+ {{ contactData['email_addresses'].length - 1 }} more</div>
                        <div v-else>show less</div>
                    </div>
                </template>
            </v-col>
        </v-row>

        

        <!-- {{ contactData['addresses'] }} -->
        <v-divider class="my-5"></v-divider>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ContactDetails',
    props: {
        contactData: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            showMoreEmail: false,
            showMorePhones: false
        }
    },
    computed: {
        emails() {
            return this.contactData['email_addresses']?.length
        },
        phones() {
            return this.contactData['phone_numbers']?.length
        },
        primaryAddress() {
            let address = this.$_.cloneDeep(this.contactData['addresses'])
            return address?.length ? address.filter(item => item.is_primary == 1) : []
        },
        mailingAddress() {
            let address = this.$_.cloneDeep(this.contactData['addresses'])
            return address?.length ? address.filter(item => item.is_primary !== 1 && item.type == 'mailing') : []
        },
        otherAddresses() {
            let address = this.$_.cloneDeep(this.contactData['addresses'])
            return address?.length ? address.filter(item => item.is_primary !== 1 && item.type != 'mailing') : []
        }
    },
    methods: {
        edit(param) {
            this.$emit('edit', param)
        },
        toReadableDate(date) {
            if(!date) return;
            return moment(date).format('MMMM DD, YYYY');
        },
    }
}
</script>

<style>

</style>