<template>
    <v-hover v-slot="{hover}">
        <v-slide-item>
            <v-card :disabled="disabled" flat width="270" :class="selected ? 'policy-card-active' : 'policy-card'" class="rounded-lg mr-3 mb-3 px-3 py-2" @click.stop="openPolicyDetailsModal(policy)" :outlined="selected">
                <v-card-text class="pa-2 cursor-pointer">
                    <div class="d-flex justify-space-between align-center">
                    <div>
                        <span class="body-3">{{ policy.type.attributes.name }} / ${{ policy.premium }}</span>
                    </div>

                    <div class="d-flex align-center">
                        <v-icon v-if="policy.status === 'active'" color="green lighten-2" fab>mdi-check-circle</v-icon>
                        <v-icon v-if="policy.status === 'inactive'" color="red lighten-2" fab>mdi-minus-circle</v-icon>
                        <v-icon v-if="policy.status === 'pending'" color="amber accent-1" fab>mdi-clock</v-icon>

                        <v-avatar color="grey lighten-1" size="20" class="white--text">{{ policy.carrier.attributes.name.charAt(0) }}</v-avatar>
                    </div>
                    </div>
                    <div>
                        <div>
                            <span class="body-3">Insured <span class="font-weight-bold">{{ policy.user == null ? '---' : policy.user.attributes.name }}</span></span>
                        </div>
                    
                        <div class="d-flex justify-space-between align-center">
                            <v-tooltip v-model="show" top close-delay="1000">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">Policy <span class="font-weight-bold">#{{ policy.policy_number }}</span></div>
                                </template>
                                <span ref="policynum">{{ policy.policy_number }}</span>
                            </v-tooltip>

                            <v-icon v-if="showCopy && hover" size="small" @click.stop="copy(policy.policy_number)">mdi-content-copy</v-icon>
                        </div>

                        <div>
                            <span class="body-3">{{ $moment(policy.start_date).format('DD MMM YYYY') }} - {{ $moment(policy.expiration_date).format('DD MMM YYYY')  }}</span>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-slide-item>
    </v-hover>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'PolicyCard',
    props: {
        policy: {
            default: () => {}
        },
        selected: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        }
    },
    methods: {
        ...mapActions(['setToast']),
        openPolicyDetailsModal(param) {
            this.isActive = false
            this.$emit('select', param)
            this.isActive = true
        },
        copy(textToCopy) {
            navigator.clipboard.writeText(textToCopy);

            this.setToast({
                show: true,
                message: 'Copied',
                type: 'success'
            })
        }
    },
    data() {
        return {
            show: false,
            showCopy: false
        }
    },
    watch: {
        show(newVal) {
            if(newVal) {
                this.showCopy = true
            }
        }
    }
}
</script>

<style lang="scss">
.policy-card-active {
    border: 1px solid var(--v-primary-base) !important
}

.policy-card {
    border: 1px solid rgba($primary-shade--light, 0.22) !important
}

.v-card--link:focus {
  background: white;
}

.v-card--link:focus::before {
  opacity: 0;
}
</style>>
