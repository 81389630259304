<template>
  <div>
    <div data-attr="personal_details">
        <div class="d-flex justify-space-between align-center mb-3">
            <div>
                <h4 class="text-uppercase">Personal Details</h4>
            </div>
            <div>
                <v-btn fab plain x-small @click="edit('personal_details')">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </div>
        </div>
        
        <v-row no-gutters class="my-2">
            <v-col sm="6">Assigned Owner</v-col>
            <v-col sm="6" class="font-weight-black">
                {{ contactData['user'] && contactData['user']['text']  }}
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Date of Birth</v-col>
            <v-col sm="6" class="font-weight-black">
                {{ toReadableDate(contactData['birthdate']) }}
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Driver License Number</v-col>
            <v-col sm="6" class="font-weight-black">
                {{ contactData['driver_license_number'] }}
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Driver License State</v-col>
            <v-col sm="6" class="font-weight-black">
                {{ contactData['driver_license_state']}}
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Lead Source</v-col>
            <v-col sm="6" class="font-weight-black">
                {{ contactData['lead_source'] }}
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Marital Status</v-col>
            <v-col sm="6" class="text-capitalize font-weight-black">
                {{ contactData['marital_status'] }}
            </v-col>
        </v-row>

        <v-row no-gutters class="my-2">
            <v-col sm="6">Occupation</v-col>
            <v-col sm="6" class="text-capitalize font-weight-black">
                {{ contactData['occupation'] }}
            </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'PersonalDetails',
    props: {
        contactData: {
            required: true,
            type: Object
        }
    },
    methods: {
        edit(param) {
            this.$emit('edit', param)
        },
        toReadableDate(date) {
            if(!date) return;
            return moment(date).format('MMMM DD, YYYY');
        },
    }
}
</script>

<style>

</style>